﻿import { h } from 'hyperapp';

import { t } from '../translations';

import { Dialog, ActionBar, DialogAction } from '../dialog';
import { all, email, required, hasErrors } from '../validation';
import { Form, TextInput, formValue, SubmitButton, ValidationErrors, valueOf } from '../forms';

import * as Document from '../document';
import * as Http from '../http';
import * as Routes from '../routes';
import * as Navigation from '../navigation';

import * as LoginPage from './login';

const CloseDialog = (state) => [
    state,
    Navigation.back()
];

const EmailChanged = (state, newValue) => ({ ...state, email: newValue });

const PasswordResetSent = (state, result) => result
    .map(_ => (({ ...state, loading: false, sent: true })))
    .resolve(error => [
        state,
        Routes.navigateToError({}, error.type)
    ]);

const SendPasswordReset = (state) => [
    { ...state, loading: true },
    Http.post({
        action: PasswordResetSent,
        url: '/forgot-password',
        data: {
            email: valueOf(state.email),
            returnUrl: state.returnUrl
        }
    })
];

const ForgotPassword = ({ state }) => {
    if (state.sent) {
        return (
            <div>
                {t('forgot-password.success-sending-mail')}
            </div>
        );
    }

    return (
        <div>
            <div>
                {t('forgot-password.description')}
            </div>
            <Form onSubmit={SendPasswordReset}>
                <TextInput value={state.email} placeholder={t('global.email')} disabled={state.loading} onChange={EmailChanged} />
                <SubmitButton label={t('forgot-password.reset-password-button')} disabled={hasErrors(state.email) || state.loading} />
                <ValidationErrors prefix="forgot-password" values={[state.email]} />
            </Form>
        </div>
    );
};

const ForgotPasswordDialog = ({ state }) => {
    return (
        <Dialog id="forgot-password-dialog" show={true}>
            <ForgotPassword state={state} />
            <ActionBar>
                <DialogAction href={Routes.login(state.returnUrl)} label="global.close-button" onClick={CloseDialog} />
            </ActionBar>
        </Dialog>
    );
};

export const title = () => t('forgot-password.title') + ' - Intelliplan AB';

export const init = (state, params) => {
    const loginPage = LoginPage.init(state, params);

    return [
        {
            loginState: loginPage[0],
            email: formValue(state.username || '', all([email('invalid-email'), required('email-required')])),
            sent: false,
            loading: false,
            returnUrl: params.returnUrl
        }, [
            ...loginPage[1],
            typeof (state.username) === 'undefined' && Routes.navigateToLogin(state, params.returnUrl)
        ]
    ];
};

export const view = state => (
    <div class="reset-password page">
        {LoginPage.view(state.loginState)}
        <ForgotPasswordDialog state={state} />
    </div>
);

export const subscriptions = state => (
    !state.loading && Document.clickOutside({ action: CloseDialog, id: 'forgot-password-dialog', attachIn: 50 })
);