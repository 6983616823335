﻿import { h } from 'hyperapp';

import { t } from '../translations';

import { all, minLength, lowerAlpha, upperAlpha, digit, required, equal, hasErrors, valid, invalid } from '../validation';
import { Form, formValue, SubmitButton, ValidationErrors, valueOf, PasswordInput } from '../forms';
import { Page } from '../page';

import * as Http from '../http';
import * as Navigation from '../navigation';
import * as Timer from '../timer';
import * as Routes from '../routes';

const PasswordChanged = (state, newValue) => ({ ...state, password: newValue });
const PasswordAgainChanged = (state, newValue) => ({ ...state, passwordAgain: newValue });

const Redirect = state => [
    state,
    Navigation.loadUrl(state.redirectTo)
];

const PasswordReset = (state, result) => result
    .map(response => [{
            ...state,
            loading: false,
            changed: true,
            redirectTo: response.redirectTo
        },
        Timer.delay(5000, Redirect)
    ])
    .resolve(error => {
        switch (error.type) {
            case 'client-error':
                return {
                    ...state,
                    loading: false,
                    error: error.details.code
                };

            default:
                return [
                    state,
                    Routes.navigateToError({}, error.type)
                ];
        }
    });

const ResetPassword = (state) => [
    { ...state, loading: true },
    Http.post({
        action: PasswordReset,
        url: '/reset-password',
        data: {
            username: state.username,
            token: state.token,
            clientId: state.clientId,
            newPassword: valueOf(state.password),
            passwordConfirmation: valueOf(state.passwordAgain)
        }
    })
];

export const title = () => t('reset-password.title') + ' - Intelliplan AB';

export const init = (state, params) => {
    return {
        username: params.username,
        password: formValue('', all([
            required('password-required'),
            minLength('weak-password', 8),
            digit('weak-password'),
            lowerAlpha('weak-password'),
            upperAlpha('weak-password'),
        ])),
        passwordAgain: formValue('', required('password-again-required')),
        token: params.ticket,
        clientId: params.clientId,
        redirectTo: null,
        loading: false,
        changed: false,
        error: null
    };
};

export const view = state => {
    let possibleErrors = [
        state.password,
        state.passwordAgain,
        equal('password-mismatch', state.password, state.passwordAgain),
        state.error ? invalid(state.error) : valid
    ];

    if (state.changed) {
        return (
            <div class="reset-password page">
                {t('reset-password.success-updating-password')}
            </div>
        )
    }

    return (
        <Page name="reset-password">
            <div>
                {t('reset-password.description')}
            </div>
            <Form onSubmit={ResetPassword}>
                <PasswordInput id="new-password" value={state.password} placeholder={t('global.password')} onChange={PasswordChanged} autocomplete="new-password" />
                <PasswordInput id="confirm-password" value={state.passwordAgain} placeholder={t('global.password-confirm')} onChange={PasswordAgainChanged} autocomplete="confirm-password" />

                <SubmitButton label={t('forgot-password.reset-password-button')} disabled={hasErrors(possibleErrors) || state.loading} />
                <ValidationErrors prefix="reset-password" values={possibleErrors} />
            </Form>
        </Page>
    );
};