﻿const runTimeout = ({ action, time }, dispatch) => {
    setTimeout(() => dispatch(action), time);
};

export const delay = (time, action) => ([
    runTimeout,
    {
        action: action,
        time: time
    }
]);