﻿const maybeApply = (value, args) => {
    if (typeof value === 'function') {
        return value.apply(null, args || []);
    }

    return value;
};

class Result {
    constructor(ok, value) {
        this.ok = ok;
        this._value = value;
    }

    map(fn) {
        return this.ok ? ok(fn(this._value)) : this;
    }

    andThen(fn) {
        return this.ok ? fn(this._value) : this;
    }

    resolve(fn) {
        return this.ok ? this._value : fn(this._value);
    }

    withDefault(defaultValue) {
        return this.ok ? this._value : maybeApply(defaultValue);
    }
}

export const ok = value => new Result(true, value);
export const err = error => new Result(false, error);