﻿export const valid = Object.freeze({ valid: true, error: '' });
export const invalid = (error) => ({ valid: false, error: error });

export const succeed = (_) => valid;
export const required = (error) => (value) => value === null || value === '' ? invalid(error || 'required') : valid;
export const email = (error) => (value) => /^.+@.+$/i.test(value) ? valid : invalid(error || 'email');

export const minLength = (error, min) => (value) => value && value.length < min ? invalid(error || 'too-short') : valid;
export const digit = (error) => (value) => /\d/.test(value) ? valid : invalid(error || 'digit-required');
export const lowerAlpha = (error) => (value) => /[a-z]/.test(value) ? valid : invalid(error || 'lower-alpha-required');
export const upperAlpha = (error) => (value) => /[A-Z]/.test(value) ? valid : invalid(error || 'upper-alpha-required');

export const equal = (error, first, second) => {
    const valid = first.current === second.current;

    return {
        pristine: first.pristine || second.pristine,
        valid: valid,
        error: valid ? null : error
    };
};

export const all = (validators) => (value) => {
    let failed = validators
        .map(validator => validator(value))
        .find(result => !result.valid);

    return failed || valid;
};

export const hasErrors = (...values) => {
    values = Array.isArray(values[0]) ? values[0] : values;

    return values.some(value => !value.valid);
};