﻿import { h } from 'hyperapp';

import { t } from '../translations';
import { Page } from '../page';

export const NotFound = {
    title: () => t('error.page-not-found') + ' - Intelliplan AB',
    init: _ => ({ }),
    view: _ => (
        <div class="not-found page">
            {t('login.page-not-found')}
        </div>
    )
};

export const Error = {
    title: () => t('error.title') + ' - Intelliplan AB',
    init: _ => ({}),
    view: _ => (
        <Page name="error">
            {t('error.generic-error')}
        </Page>
    )
};