﻿import { h } from 'hyperapp';

import * as Http from '../http';
import * as Navigation from '../navigation';
import * as Routes from '../routes';

import { t } from '../translations';

import { appendQueryParameters } from '../uri';
import { preventDefault } from '../events';
import { all, email, required, hasErrors } from '../validation';
import { CheckBox, Form, PasswordInput, AlternateSubmitButton, SubmitButton, LinkedInButton, TextInput, ValidationErrors, formValue, valueOf, detectAutofill } from '../forms';
import { Page } from '../page';

const serializable = state => ({
    username: valueOf(state.username),
    password: valueOf(state.password),
    rememberMe: state.rememberMe,
    returnUrl: state.returnUrl,
    layout: state.layout
});

const AutofillDetected = (state) => ({ ...state, autofilled: true });

const UsernameChanged = (state, newValue) => ({ ...state, username: newValue, autofilled: false, error: null });
const PasswordChanged = (state, newValue) => ({ ...state, password: newValue, autofilled: false, error: null });
const RememberMeChanged = (state, newValue) => ({ ...state, rememberMe: newValue });

const LoggedIn = (state, result) => result
    .map(response => {
        switch (response.state) {
            case 'success':
                return [
                    state,
                    Navigation.loadUrl(state.returnUrl)
                ];

            case '2fa-required':
                return [
                    state,
                    Routes.navigateToTwoFactorLogin(serializable(state), state.returnUrl)
                ];

            default:
                return [
                    state,
                    Routes.navigateToError({}, 'unexpected-response')
                ];
        }
    })
    .resolve(error => {
        switch (error.type) {
            case 'client-error':
                return {
                    ...state,
                    processing: false,
                    error: error.details.code
                };

            default:
                return [
                    state,
                    Routes.navigateToError({}, error.type)
                ];
        }
    });
 
const LogIn = (state, ev) => [
    { ...state, processing: true },
    Http.post({
        action: LoggedIn,
        url: '/login',
        data: serializable(state)
    })
];

const ExternalProvidersLoaded = (state, result) => result
    .map(response => [
        { ...state, loading: false, externalLogins: response.providers },
        detectAutofill(AutofillDetected, 'username', 'password')
    ])
    .resolve(error => [
        state,
        Routes.navigateToError({}, error.details ? error.details.code : error.type)
    ]);

const ForgotPasswordClicked = (state) => [
    state,
    Routes.navigateToForgotPassword(serializable(state), state.returnUrl)
];

const RememberMe = ({ state }) => {
    const disabled = state === 'never' || state === 'always';
    const checked = state === 'always' || state === true;

    return (
        <CheckBox id={'remember-me'} label={t("login.remember-me")} disabled={disabled} checked={checked} onChange={RememberMeChanged} />
    );
};

const PasswordLogin = ({ state }) => (
    <div class="password-login">
        <Form onSubmit={LogIn}>
            <TextInput id={'username'} value={state.username} placeholder={t("global.email")} autocomplete={'current-username'} disabled={state.processing} onChange={UsernameChanged} />
            <PasswordInput id={'password'} value={state.password} placeholder={t("global.password")} autocomplete={'current-password'} disabled={state.processing} onChange={PasswordChanged} />
            <RememberMe state={state.rememberMe} />
            <SubmitButton label={t("login.login-button")} disabled={state.processing || (!state.autofilled && hasErrors(state.username, state.password))} />
            <ValidationErrors prefix={'login'} values={[state.username, state.password, state.error]} />
        </Form>
    </div>
);

const MaybeDivider = ({ showDividers, groupId, lastGroupId }) => (
    showDividers && groupId !== lastGroupId
        ? <div className="divider"><span>{t("login.or")}</span></div>
        : null
);

const ExternalLogins = ({ showDividers, disabled, providers, returnUrl }) => {
    if (!providers) {
        return null;
    }

    let lastGroupId = 0;
    
    return (
        <div>
            {
                providers.map((provider, index) => {
                    let row = (
                            <Form method="post" action={appendQueryParameters('/login/' + (provider.type === 'OpenIdConnect' ? 'oidc' : provider.type.toLowerCase()), { returnUrl, scheme: provider.scheme })}>
                                <MaybeDivider showDividers={showDividers} groupId={provider.groupId} lastGroupId={lastGroupId} />
                                {
                                    provider.type === 'LinkedIn'
                                        ? <LinkedInButton disabled={disabled} label={t("login.login-using-external-provider") + ' ' + provider.name}/>
                                        : <AlternateSubmitButton disabled={disabled} className="external-login" label={t("login.login-using-external-provider") + ' ' + provider.name}/>
                                }
                            </Form>
                    );                    
                    
                    lastGroupId = provider.groupId;      
                    
                    return row;
                })              
            }
        </div>
    );
};

const ForgotPassword = ({ returnUrl }) => (
    <div class="form-field forgot-password">
        <a href={Routes.forgotPassword(returnUrl)} onClick={preventDefault(ForgotPasswordClicked)}>{t("login.forgot-password-link")}</a>
    </div>
);

export const title = () => t('login.title') + ' - Intelliplan AB';

export const init = (state, params) => {
    return [{
        layout: state.layout,
        username: formValue(state.username || '', all([
            required('username-required'),
            email('invalid-email')
        ])),
        password: formValue(state.password || '', required('password-required')),
        rememberMe: state.rememberMe || (settings.rememberMe === 'askuser' ? false : settings.rememberMe),
        returnUrl: params.returnUrl || null,
        error: params.error || null,
        loading: true ,
        autofilled: false,
    }, [
        !params.returnUrl && Routes.navigateToHome(),
        Http.get({
            url: '/api/login/external-providers',
            action: ExternalProvidersLoaded
            })
    ]
]};

export const view = (state) => {
    return state.layout.showPasswordLogin ? (
            <Page name="login" loading={state.loading}>
                <PasswordLogin state={state} />
                <ExternalLogins showDividers={true} disabled={state.processing} providers={state.externalLogins} returnUrl={state.returnUrl} />
                <ForgotPassword returnUrl={state.returnUrl} />
            </Page>
        ) : (
            <Page name="login" loading={state.loading}>
                <ExternalLogins showDividers={false} disabled={state.processing} providers={state.externalLogins} returnUrl={state.returnUrl} />
            </Page>
        );
}
