﻿import { h } from 'hyperapp';

import * as Http from '../http';
import * as Routes from '../routes';

import { t } from '../translations';

import { Page } from '../page';
import { Button, AlternateButton, DangerButton, LeftToRight } from '../forms';
import { Dialog, Title as DialogTitle } from '../dialog';

const fetchTerms = token => Http.get({
    url: `/api/users/${token}/consent`,
    action: TermsFetched
});

const CloseDialog = state => ({ ...state, dialog: 'none' });

const ShowDeleteDialog = state => ({ ...state, dialog: 'delete' });

const DeleteConsent = state => [
    { ...state, processing: true },
    Http.post({
        url: `/api/users/${state.token}/consent/all/delete`,
        action: Deleted
    })
];

const Deleted = (state, result) => result
    .map(response => ({
        ...state,
        processing: false,
        dialog: response.inProgress ? 'delete-pending' : 'deleted'
    }))
    .resolve(error => [
        state,
        Routes.navigateToError({}, error.details.code || error.type)
    ]);

const FetchTerms = (state) => [
    { ...state, processing: true },
    fetchTerms(state.token)
];

const TermsFetched = (state, result) => result
    .map(response => ({
        ...state,
        terms: response.terms,
        loading: false,
        processing: false,
        dialog: 'none'
    }))
    .resolve(error => {
        switch (error.type) {
            case 'client-error':
                return {
                    ...state,
                    loading: false,
                    error: error.details.code
                };

            default:
                return [
                    state,
                    Routes.navigateToError({}, error.details.code || error.type)
                ];
        }
    });

const AcceptTerms = state => [
    { ...state, processing: true },
    Http.post({
        url: `/api/users/${state.token}/consent/${state.terms.id}/accept`,
        action: TermsAccepted
    })
];

const TermsAccepted = (state, result) => result
    .map(response => ({
        ...state,
        processing: false,
        dialog: response.termsUpdated ? 'terms-updated' : 'accepted',
        consent: { validTo: response.validTo }
    }))
    .resolve(error => [
        state,
        Routes.navigateToError({}, error.type)
    ]);

const Title = ({ title }) => {
    if (title === null || /^\s*$/.test(title)) {
        return null;
    }

    return (
        <h4>{title}</h4>
    );
};

const Text = ({ state, ...attrs }) => {
    if (state == null) {
        return null;
    }

    return (
        <div>
            <Title title={state.title} />
            <p {...attrs} innerHTML={state.text} />
        </div>
    );
};

const Controls = ({ processing }) => (
    <div class="controls">
        <LeftToRight>
            <AlternateButton disabled={processing} onClick={ShowDeleteDialog} label={t('user-consent.delete-button')} className="delete" />
            <Button disabled={processing} onClick={AcceptTerms} label={t('user-consent.accept-button')} />
        </LeftToRight>
    </div>
);

const AcceptedDialog = ({ state }) => (
    <Dialog show={true}>
        <DialogTitle title={t('user-consent.accepted-title')} />
        <div className="text" innerHTML={`${t('user-consent.accepted-text')} ${state.consent.validTo}.`} />
        <div className="control">
            <Button label={t('global.done-button')} onClick={CloseDialog} />
        </div>
    </Dialog>
);

const TermsUpdatedDialog = ({ state }) => (
    <Dialog show={true}>
        <DialogTitle title={t('user-consent.terms-updated-title')} />
        <div className="text" innerHTML={t('user-consent.terms-updated-text')} />
        <div className="control">
            <Button disabled={state.processing} label={t('global.close-button')} onClick={FetchTerms} />
        </div>
    </Dialog>
);

const DeletedDialog = ({ state }) => (
    <Dialog show={true}>
        <DialogTitle title={t('user-consent.deleted-title')} />
        <div className="text" innerHTML={t('user-consent.deleted-text')} />
        <div className="control">
            <Button label={t('global.done-button')} onClick={CloseDialog} />
        </div>
    </Dialog>
);

const DeletePendingDialog = ({ state }) => (
    <Dialog show={true}>
        <DialogTitle title={t('user-consent.delete-requested-title')} />
        <div className="text" innerHTML={t('user-consent.delete-requested-text')} />
        <div className="control">
            <Button label={t('global.done-button')} onClick={CloseDialog} />
        </div>
    </Dialog>
);

const DeleteDialog = ({ state }) => (
    <Dialog show={true}>
        <DialogTitle title={t('user-consent.delete-title')} />
        <div className="text" innerHTML={t('user-consent.delete-text')} />
        <div className="control">
            <LeftToRight>
                <Button disabled={state.processing} label={t('global.back-button')} onClick={CloseDialog} />
                <DangerButton disabled={state.processing} label={t('user-consent.delete-button')} onClick={DeleteConsent} />
            </LeftToRight>
        </div>
    </Dialog>
);

const ConfirmationDialog = ({ state }) => {
    switch (state.dialog) {
        case 'delete':
            return (<DeleteDialog state={state} />);

        case 'accepted':
            return (<AcceptedDialog state={state} />);

        case 'deleted':
            return (<DeletedDialog state={state} />);

        case 'delete-pending':
            return (<DeletePendingDialog state={state} />);

        case 'terms-updated':
            return (<TermsUpdatedDialog state={state} />)

        default:
            return null;
    }
};

export const title = () => t('user-consent.title') + ' - Intelliplan AB';

export const init = (state, params) => ([
    {
        loading: true,

        token: params.token,

        terms: {},
        consent: {},

        dialog: 'none'
    },
    fetchTerms(params.token)
]);

export const view = state => {
    if (state.error) {
        return (
            <Page name="user-consent" loading={state.loading}>
                {t("user-consent." + state.error)}
            </Page>
        );
    }

    return (
        <Page name="user-consent" loading={state.loading}>
            <Text state={state.terms.pre} />
            <Text state={state.terms.main} className="middle" />
            <Text state={state.terms.post} />

            <Controls processing={state.processing} />
            <ConfirmationDialog state={state} />
        </Page>
    )
};