﻿import { isTruthy } from './utils';

const flatten = (arr, target) => arr.reduce((acc, item) => {
    if (Array.isArray(item)) {
        return flatten(item, acc);
    }

    acc.push(item);

    return acc;
}, target || []);

const runBatch = ({ batchedEffects }, dispatch) => {    
    for (let i = 0; i < batchedEffects.length; i += 2) {
        batchedEffects[i](batchedEffects[i + 1], dispatch);
    }
};

export const none = null;

export const batch = effects => {
    if (!effects || !Array.isArray(effects)) {
        return effects;
    }
    
    const batchedEffects = flatten(effects)
        .filter(isTruthy);
    
    return [
        runBatch,
        { batchedEffects: batchedEffects }
    ];
};