﻿import { appendQueryParameters } from './uri';

const createPushStateEvent = state => new CustomEvent('x-pushstate', { detail: state });

const listenForRouteChanges = ({ action, routeParser }, dispatch) => {
    const handleRouteChange = (ev) => {
        const route = routeParser(window.location);

        dispatch(action, { pushState: ev.state || ev.detail || {}, route });
    };

    window.addEventListener('popstate', handleRouteChange);
    window.addEventListener('x-pushstate', handleRouteChange);

    return () => {
        window.removeEventListener('popstate', handleRouteChange);
        window.removeEventListener('x-pushstate', handleRouteChange);
    };
};

const modifyHistory = modify => ({ state, title, path, params }) => {
    const newPath = appendQueryParameters(path, params);

    modify.call(history, state, title, newPath);

    window.dispatchEvent(createPushStateEvent(state));
};

const runPushState = modifyHistory(history.pushState);
const runReplaceState = modifyHistory(history.replaceState);

const runGo = ({ steps }) => history.go(steps);

export const routeChanges = (action, routeParser) => ([
    listenForRouteChanges,
    {
        action: action,
        routeParser: routeParser
    }
]);

export const changeUrl = (path, { state, title, params }) => ([
    runPushState,
    {
        state: state,
        title: title,
        path: path,
        params: params
    }
]);

export const replaceUrl = (path, { state, title, params }) => ([
    runReplaceState,
    {
        state: state,
        title: title,
        path: path,
        params: params
    }
]);

export const back = () => ([
    runGo,
    {
        steps: -1
    }
]);

export const loadUrl = url => {
    window.location = url;

    return [
        () => { }
    ]
};