﻿import { h } from 'hyperapp';

import * as Document from './document';

import * as Translations from './translations';

const toName = (language) => {
    switch (language) {
        case 'da':
            return 'DANSK';

        case 'en':
            return 'ENGLISH';

        case 'fi':
            return 'SUOMI';

        case 'nb':
            return 'NORSK BOKMÅL';

        case 'sv':
            return 'SVENSKA';

        default:
            throw new Error(`Language '${language}' not supported.`);
    }
};

const accept = callback => (state, ev) => {
    if (ev.key === 'Enter' || ev.key === ' ') {
        return callback(state, ev);
    }

    return state;
};

const mapState = (state, prop, fn) => {
    let newState = { ...state };

    newState[prop] = fn(state[prop]);

    return newState;
};

const changeMenuState = (state, value) => mapState(state, 'layout', layout => ({ ...layout, menu: value }));

const ToggleMenu = state => changeMenuState(state, state.layout.menu === 'hidden' ? 'visible' : 'hidden');
const CloseMenu = state => changeMenuState(state, 'hidden');

const LanguageChanged = (state, language) => {
    return mapState(state, 'layout', layout => ({ ...layout, language }));
};

const SelectLanguage = (state, lang) => [
    changeMenuState(state, 'hidden'),
    Translations.changeLanguage({
        action: LanguageChanged,
        language: lang
    })
];

const LanguageMenuItem = ({ value }) => (
    <li tabIndex="0" onClick={[SelectLanguage, value]}>{toName(value)}</li>
);

export const LanguageMenu = ({ selected, menuState }) => {
    let selectableLanguages = ['da', 'en', 'fi', 'nb', 'sv']
        .filter(lang => lang !== selected);

    return (
        <div id="language-menu">
            <div tabIndex="0" className="button" onKeyDown={accept(ToggleMenu)} onClick={ToggleMenu}>{toName(selected)}</div>
            <ul className={menuState}>
                {selectableLanguages.map(lang => (<LanguageMenuItem value={lang} />))}
            </ul>
        </div>
    )
};

export const subscriptions = state =>
    state.menu === 'visible' && Document.clickOutside({ action: CloseMenu, id: 'language-menu' });
