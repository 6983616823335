﻿import { h } from 'hyperapp';

import { preventDefault } from './events';
import { t } from './translations';
import { succeed } from './validation';

const id = (state) => state;

const validate = (value) => {
    let result = value.validator(value.current);

    return { ...value, valid: result.valid, error: result.error };
};

const map = (callback, fn) =>
    (state, value, ev) => callback(state, fn(value, ev.target.value), ev);

const blurred = (callback) => map(callback,
    value => ({ ...value, pristine: false }));

const textChanged = (callback) => map(callback,
    (value, newValue) => validate({ ...value, current: newValue }));

const checkChanged = (callback) =>
    (state, ev) => callback(state, ev.target.checked, ev);

export const formValue = (value, validator) => {
    return validate({
        current: value,
        pristine: true,
        valid: true,
        error: '',
        validator: validator || succeed
    });
};

export const valueOf = value => value.current;

export const invalidate = (value, error) => ({ ...value, valid: false, error: error });

const classes = (...args) => {
    const reduceNested = (result, cls) => {
        if (!cls) {
            return result;
        }

        if (Array.isArray(cls)) {
            return cls.reduce(reduceNested, result);
        }

        result.push(cls);

        return result;
    };

    return args.reduce(reduceNested, [])
        .join(' ');
};

export const Hidden = ({ id, value }) => (
    <input id={id} type="hidden" value={value} />
);

const Input = type => ({ id, className, placeholder, autocomplete, value, onChange }) => {
    placeholder = placeholder || '';
    autocomplete = autocomplete || '';

    const classes = [
        value.pristine ? 'pristine' : 'dirty',
        value.valid ? 'valid' : 'invalid',
        className || ''
    ];

    return (
        <FormRow>
            <input className={classes.join(' ')} id={id} type={type} value={value.current} placeholder={placeholder} autoComplete={autocomplete} onInput={[textChanged(onChange), value]} onBlur={[blurred(onChange), value]} />
        </FormRow>
    );
};

export const Form = ({ onSubmit, ...rest }, children) => (
    <form {...rest} onSubmit={onSubmit ? preventDefault(onSubmit) : id} >
        {children}
    </form>
);

export const FormRow = ({ className, ...rest }, children) => (
    <div {...rest} className={'form-field ' + (className || '')} >
        {children}
    </div>
);

export const LeftToRight = ({ columns }, children) => (
    <div className={classes('left-to-right', 'columns-' + (columns || children.length))}>
        {children}
    </div>
);


export const TextInput = Input('text');
export const PasswordInput = Input('password');

export const CheckBox = ({ id, className, label, disabled, checked, onChange }) => (
    <FormRow className={className}>
        <input id={id} type="checkbox" checked={checked} disabled={disabled} onChange={checkChanged(onChange)} />
        <label for={id}> {label}</label>
    </FormRow>
);

export const Button = ({ label, onClick, ...attrs }) => (
    <FormRow>
        <button {...attrs} onClick={onClick || id}>{label}</button>
    </FormRow>
); 

export const DangerButton = ({ className, ...attrs }) => (<Button {...attrs} className={classes(className, 'danger')} />);

export const AlternateButton = ({ className, ...attrs }) => (<Button {...attrs} className={classes(className, 'alternate')} />);

export const SubmitButton = (attrs) => (<Button {...attrs} type="submit" />);
export const AlternateSubmitButton = (attrs) => (<AlternateButton {...attrs} type="submit" />);

export const LinkedInButton = ({ label, onClick, className, ...attrs }) => (
    <FormRow>
        <button {...attrs} className={classes(className, 'linkedin')} type="submit" onClick={onClick || id}><i className="fa fa-linkedin"></i>{label}</button>
    </FormRow>
);

export const ValidationErrors = ({ className, prefix, values }) => {
    const coerceToError = value => typeof (value) !== 'string' ? value : { valid: false, pristine: false, error: value };
    const error = value => value && !value.valid && !value.pristine;

    const ValidationError = ({ value }) => {
        let errorMessage = t(prefix + ".error-" + value.error);

        let classes = [
            'validation-error',
            value.pristine ? 'pristine' : 'dirty',
            value.valid ? 'valid' : 'invalid',
            className || ''
        ];

        return (
            <li className={classes.join(' ')}>
                {errorMessage}
            </li>
        )
    };
    
    const errors = values
        .map(coerceToError)
        .filter(error);
    
    if (!errors.length) {
        return null;
    }

    return (
        <ul class="validation-errors">
            {errors.map(value => (<ValidationError value={value} />))}
        </ul>
    );
};


const runAutofillDetection = ({ action, elementIds }, dispatch) => {
    const matchesWebkitAutofillPsuedoClass = id => {
        const element = document.getElementById(id);

        return element && element.matches(':-webkit-autofill');
    };

    const detect = retry => () => {
        if (elementIds.every(matchesWebkitAutofillPsuedoClass)) {
            dispatch(action, {});

            return;
        }

        if (retry < 20) {
            setTimeout(detect(retry + 1), 15);
        }
    };

    detect(0)();
};

export const detectAutofill = (action, ...elementIds) => ([
    runAutofillDetection,
    {
        action,
        elementIds
    }
]);