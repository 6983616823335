﻿import { appendQueryParameters, parse, oneOf, map, query, s, string, stringParam } from './uri';

import * as Navigation from "./navigation";

const homePath = '/web';
const loginPath = '/web/login';
const twoFactorLoginPath = '/web/login/two-factor';
const forgotPasswordPath = '/web/login/forgot-password';
const errorPath = '/web/error';

export const login = (returnUrl) => appendQueryParameters(loginPath, { returnUrl });
export const twoFactorLogin = (returnUrl) => appendQueryParameters(twoFactorLoginPath, { returnUrl });
export const forgotPassword = (returnUrl) => appendQueryParameters(forgotPasswordPath, { returnUrl });

export const navigateToHome = () => Navigation.changeUrl(homePath, {
    state: {}, params: {}
});

export const navigateToLogin = (state, returnUrl) => Navigation.changeUrl(loginPath, {
    state: state,
    params: {
        returnUrl
    }
});

export const navigateToTwoFactorLogin = (state, returnUrl) => Navigation.changeUrl(twoFactorLoginPath, {
    state: state,
    params: {
        returnUrl
    }
});

export const navigateToForgotPassword = (state, returnUrl) => Navigation.changeUrl(forgotPasswordPath, {
    state: state,
    params: {
        returnUrl
    }
});

export const navigateToError = (state, error) => Navigation.changeUrl(errorPath, {
    state: state,
    params: {
        error
    }
});

const route = name => params => ({ name, params });

export const Home = route('home');
export const Error = route('error');
export const Login = route('login')
export const TwoFactorLogin = route('two-factor-login');
export const LogOut = route('log-out');
export const ForgotPassword = route('forgot-password');
export const ResetPassword = route('reset-password');
export const UserConsent = route('user-consent');
export const NotFound = route('not-found');

const returnUrl = stringParam('returnUrl').optional();
const error = stringParam('error').optional();

const routeParser = s('web').andThen(oneOf([
    map(Home, []),
    map(Login, ['login', query(
        returnUrl, error
    )]),
    map(TwoFactorLogin, ['login', 'two-factor', query(returnUrl)]),
    map(ForgotPassword, ['login', 'forgot-password', query(returnUrl)]),
    map(ResetPassword, ['reset-password', query(
        stringParam('username'),
        stringParam('ticket'),
        stringParam('clientId').optional()
    )]),
    map(LogOut, ['logout']), 
    map(UserConsent, ['users', string('token'), 'consent']),
    map(Error, ['error', query(error)])
]));

export const parseRoute = location => parse(location, routeParser) || NotFound({
    location: location
});