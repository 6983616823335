﻿const listenForClickOutside = ({ action, id, attachIn }, dispatch) => {
    const handler = (ev) => {
        const containerElement = document.getElementById(id);

        if (!containerElement) {
            return;
        }

        let currentTarget = ev.target;

        while (currentTarget && currentTarget !== containerElement) {
            currentTarget = currentTarget.parentElement;
        }

        if (!currentTarget) {
            dispatch(action);
        }
    };

    const timeout = setTimeout(() => document.addEventListener('click', handler), attachIn || 0);

    return () => {
        clearTimeout(timeout);

        document.removeEventListener('click', handler);
    };
};

const runChangeTitle = ({ newTitle }) => {
    document.title = newTitle;
};

export const clickOutside = (params) => ([
    listenForClickOutside,
    {
        ...params
    }
]);

export const changeTitle = (newTitle) => ([
    runChangeTitle,
    { newTitle }
]);