﻿import '@babel/polyfill';
import './ie-polyfill';

import { app } from 'hyperapp';

import * as Effect from './effect';
import * as Navigation from './navigation';
import * as Routes from './routes';
import * as Document from './document';

import * as Layout from './pages/layout';
import * as HomePage from './pages/home';
import * as LoginPage from './pages/login';
import * as TwoFactorLoginPage from './pages/two-factor';
import * as ForgotPasswordPage from './pages/forgot-password';
import * as ResetPasswordPage from './pages/reset-password';
import * as UserConsentPage from './pages/user-consent';

import { Error as ErrorPage, NotFound as NotFoundPage } from './pages/error';

const settings = window.settings;

const LoadingPage = {
    init: _ => { },
    view: _ => null
};

const initPage = (pushState, route) => {
    let data = null;

    switch (route.name) {
        case 'home':
            return [
                HomePage.init(),
                Document.changeTitle(HomePage.title())
            ];

        case 'login': {
            data = LoginPage.init(pushState, route.params);

            return [
                data[0], 
                [
                    ...data[1],
                    Document.changeTitle(LoginPage.title())
                ]
            ];
        }

        case 'two-factor-login': {
            data = TwoFactorLoginPage.init(pushState, route.params);

            return [
                data[0], 
                [
                    Document.changeTitle(TwoFactorLoginPage.title())
                ]
            ];
        }

        case 'forgot-password':
            data = ForgotPasswordPage.init(pushState, route.params);

            return [
                data[0], 
                [
                    ...data[1],
                    Document.changeTitle(TwoFactorLoginPage.title())
                ]
            ];

        case 'reset-password':
            return [
                ResetPasswordPage.init(pushState, route.params), 
                Document.changeTitle(TwoFactorLoginPage.title())
            ];

        case 'user-consent':
            data = UserConsentPage.init(pushState, route.params);

            return [
                data[0], [
                    data[1],
                    Document.changeTitle(UserConsentPage.title())
                ]
            ];

        case 'error':
            return [
                ErrorPage.init(),
                Document.changeTitle(ErrorPage.title())
            ];

        default:
            return [
                NotFoundPage.init(),
                Document.changeTitle(NotFoundPage.title())
            ];
    }
};

const initRoute = (state, pushState, route) => {
    const pageData = initPage(pushState, route);

    let pageState = pageData;
    let pageEffects = null;

    if (Array.isArray(pageData)) {
        pageState = pageData[0];
        pageEffects = pageData[1];
    }
    let newState = {
        ...pageState,
        route: route.name,
        layout: state.layout
    };

    return [newState, Effect.batch(pageEffects)];
};

const RouteChanged = (state, { pushState, route }) => {
    return initRoute(state, pushState, route)
};

const page = (state) => {
    switch (state.route) {
        case 'home':
            return HomePage.view(state);

        case 'login':
            return LoginPage.view(state);

        case 'two-factor-login':
            return TwoFactorLoginPage.view(state);

        case 'forgot-password':
            return ForgotPasswordPage.view(state);

        case 'reset-password':
            return ResetPasswordPage.view(state);

        case 'user-consent':
            return UserConsentPage.view(state);

        case null:
            return LoadingPage.view(state);

        case 'error':
            return ErrorPage.view(state);

        default:
            return NotFoundPage.view(state);
    }
};

const subscriptions = (state) => {
    switch (state.route) {
        case 'forgot-password':
            return ForgotPasswordPage.subscriptions(state);

        default:
            return null;
    }
};

app({
    init: _ => {
        const route = Routes.parseRoute(window.location);
        const state = {
            layout: Layout.init(window.settings)
        };
        
        return initRoute(state, {}, route);
    },
    view: state => Layout.view(state.layout, page(state)),
    subscriptions: state => [
        Layout.subscriptions(state.layout),
        Navigation.routeChanges(RouteChanged, Routes.parseRoute),
        subscriptions(state)
    ],
    container: document.getElementById('app')
});