﻿import { h } from 'hyperapp';

import * as Menu from '../menu';

import { t } from '../translations';

const Logo = ({ url }) => {
    let attrs = {};

    if (url) {
        attrs.style = { backgroundImage: 'url(' + url + ')' };
    }

    return (
        <div className="logo" {...attrs} />
    );
};

const LicensedTo = ({ to, email }) => { 
    
   let emailLink = email ? (<p><a href={'mailto:' + email}>{email}</a></p>) : (""); 
    return (
        <div class="licensed-to">
            <p>{t("global.licensed-to")} {to}</p>
            {emailLink}
        </div>
    );
}

const PoweredBy = () => (
    <div class="powered-by">
        POWERED BY <span class="logo"></span>
    </div>
);

export const Layout = ({ state }, children) => (
    <div className="container">
        <Menu.LanguageMenu selected={state.language} menuState={state.menu} />
        <Logo url={state.logoUrl} />
        {children}
        <LicensedTo to={state.licensedTo} email={state.supportEmail} />
        <PoweredBy />
    </div>
);

export const init = settings => ({
    menu: 'hidden',
    language: settings.language || 'en',
    logoUrl: settings.logoUrl,
    licensedTo: settings.licensedTo || null,
    supportEmail: settings.supportEmail,
    showPasswordLogin: settings.showPasswordLogin
});

export const view = (state, page) => (
    <Layout state={state}>
        {page}
    </Layout>
);

export const subscriptions = state => [
    Menu.subscriptions(state)
];