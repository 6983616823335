﻿import { h } from 'hyperapp';
import { t } from '../translations';
import { Page } from '../page';

export const title = () => t('home.title') + ' - Intelliplan AB';

export const init = () => { };

export const view = _ => (
    <Page name="home">
        {t('home.description')}
    </Page>
); 