﻿import { ok, err } from './result';

const coalesce = (value, ...defaultValues) => {
    if (value !== undefined) {
        return value;
    }

    for (let i = 0; i < defaultValues.length; ++i) {
        if (defaultValues[i] !== undefined) {
            return defaultValues[i];
        }
    }

    return null;
};

class Parser {
    static define(func) {
        return new Parser(func);
    }

    constructor(func) {
        this.run = func;
    }

    map(func) {
        return map(func, this);
    }

    andThen(parser) {
        return andThen(this, parser);
    }

    or(parser) {
        return or(this, parser);
    }

    optional(defaultValue) {
        return optional(this, defaultValue);
    }

    log(name) {
        const parser = this;

        return Parser.define(state => {
            console.log(`parsing ${name}`);

            const result = parser.run(state);

            if (result.ok) {
                console.log(`parsing ${name} succeeded`);
            }
            else {
                console.log(`parsing ${name} failed`);
            }

            return result;
        });
    }
}

class ParserState {
    static new(location, value) {
        return new ParserState(0, splitPath(location.pathname), parseQueryParameters(location.search), value)
    }

    constructor(current, segments, parameters, value) {
        this._current = current;
        this._segments = segments;
        this._parameters = parameters;
        this.value = value;
    }

    get current() {
        return this._segments[this._current];
    }

    get endOfInput() {
        return this._current >= this._segments.length;
    }

    get remaining() {
        return this._segments.slice(this._current);
    }

    param(name) {
        return this._parameters[name];
    }

    succeed() {
        return new ParserState(this._current + 1, this._segments, this._parameters, this.value);
    }

    apply(arg) {
        return this.map(value => value(arg));
    }

    withParameter(name, value) {
        return this.map(v => ({
            ...(v || {}),
            [name]: value
        }));
    }

    map(func) {
        return new ParserState(this._current, this._segments, this._parameters, func(this.value))
    }
}

const splitPath = path => {
    if (path === '/') {
        return [];
    }
    
    return path
        .replace(/^\/|\/$/g, '')
        .split('/');
};

const parseQueryParameters = query => {
    if (query === '') {
        return {};
    }

    return query
        .substring(1) // remove leading '?' 
        .split('&')
        .map(s => s.split('='))
        .reduce((params, pair) => {
            params[pair[0]] = typeof (pair[1]) !== 'undefined' ? decodeURIComponent(pair[1]) : true;

            return params;
        }, {});
};

export const succeed = Parser.define(state => ok(state));
export const fail = error => Parser.define(_ => err(error));

const endOfInput = Parser.define(state => state.endOfInput ? ok(state) : err(`Expected the end of the path, but the following segments still remain: ${state.remaining.join('/')}`));

export const s = segment => Parser.define(state => {
    if (state.endOfInput) {
        return err(`Expected "${segment}" but instead got to the end of the path.`);
    }

    if (state.current !== segment) {
        return err(`Expected "${segment}" but instead got "${state.current}".`);
    }

    return ok(state.succeed());
});

export const optional = (parser, defaultValue) => { 
    defaultValue = coalesce(defaultValue, null);

    return Parser.define(state => ok(parser.run(state).withDefault(state))); // I think this is a typo and `defaultValue` should be in .withDefault instead of `state`
};

export const string = name => Parser.define(state => {
    if (state.endOfInput) {
        return err('Expected a string parameter but instead got to the end of the path.')
    }

    const newState = state
        .withParameter(name, state.current)
        .succeed();

    return ok(newState);
});

export const stringParam = name => Parser.define(state => {
    const paramValue = state.param(name);
    const paramValueType = typeof (paramValue);

    if (paramValue === undefined) {
        return err(`Expected query parameter "${name}" but it was not found.`)
    }

    if (typeof paramValue !== 'string') {
        return err(`Expected query parameter "${name}" to be a string but it was ${paramValueType}.`);
    }

    return ok(state.withParameter(name, paramValue));
});

export const constant = (name, value) => Parser.define(state => ok(state.withParameter(name, value)));

export const custom = func => Parser.define(func);

export const query = (...queryParsers) => {
    const parser = queryParsers.reduce(andThen, succeed);

    return Parser.define(state => {
        if (!state.endOfInput) {
            return err('Query parameters can only be parsed at the end of the input. Make sure the query-parser is called as the last parser in your parser definition.');
        }

        return parser.run(state);
    });
};

const toParser = parser => {
    const type = typeof (parser);

    switch (type) {
        case "string":
            return s(parser);

        case "function":
            return custom(parser);

        case "object":
            if (parser instanceof Parser) {
                return parser;
            }

            if (Array.isArray(parser)) {
                return parser
                    .map(toParser)
                    .reduce(andThen, succeed);
            }

            throw new Error('Cannot convert object to a parser. It must either be an array of an instance of Parser');

        default:
            throw new Error(`Cannot convert type ${type} to a parser. Only string, function, object and array is supported.`);
    }
};

export const map = (func, parser) => {
    parser = toParser(parser);

    return Parser.define(state => {
        return parser.run(state)
            .map(newState => newState.map(func));
    });
};

export const andThen = (left, right) => Parser.define(state => left.run(state)
    .andThen(newState => right.run(newState)));

export const or = (first, second) => Parser.define(state => {
    const firstResult = first.run(state);

    const succeeded = firstResult
        .map(s => s.endOfInput)
        .withDefault(false);

    if (succeeded) {
        return firstResult;
    }

    return second.run(state);
});

export const oneOf = parsers => parsers
    .map(toParser)
    .map(parser => parser.andThen(endOfInput))
    .reduce((second, first) => or(first, second), fail('Expected one of the given parser to match, but none did.'));

export const parse = (location, parser) => parser
    .run(ParserState.new(location, {}))
    .map(s => s.value)
    .withDefault(null);


export const appendQueryParameters = (url, params) => {
    let query = '';
    let separator = url.indexOf('?') > -1 ? '&' : '?';

    for (let key in params) {
        if (params.hasOwnProperty(key)) {
            query += separator + encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);

            separator = '&';
        }
    }

    return url + query;
};