﻿window.translations = window.translations || {};

window.translations.selected = window.translations.selected || 'en';
window.translations.cache = window.translations.cache || {
    'en': {}
};

const selectedLanguage = () => {
    return translations.cache[translations.selected];
};

window.translations.stats = (() => {
    let storage = null;

    if (!window.sessionStorage) {
        return {
            get missing() { return null; },
            get unused() { return null; },

            use() { },
            miss() { }
        };
    }

    if (window.location.hostname.indexOf('localhost') > -1) {
        storage = window.localStorage;
    } else {
        storage = window.sessionStorage;
    }

    const key = 'translation.stats';

    const loadOrInitialize = () => {
        const serialized = storage.getItem(key);

        let missing = new Set();
        let unused = new Set(Object.keys(selectedLanguage()) || []);

        if (serialized) {
            let deserialized = JSON.parse(serialized);

            missing = new Set(deserialized.missing || []);
            unused = new Set(deserialized.unused || []);
        }

        return {
            missing,
            unused
        };
    };

    const store = items => {
        const serialized = JSON.stringify({
            missing: [...items.missing],
            unused: [...items.unused]
        });

        storage.setItem(key, serialized);
    };

    let storedItems = loadOrInitialize();

    const updateStorage = (fn) => {
        storedItems = fn(storedItems);

        store(storedItems);
    };

    return {
        get missing() { return storedItems.missing; },
        get unused() { return storedItems.unused; },

        use(key) {
            updateStorage(items => {
                items.unused.delete(key);

                return items;
            });
        },
        miss(key) {
            updateStorage(items => {
                items.missing.add(key);

                return items;
            });
        }
    };
})();

const get = (language) => {
    if (translations.cache[language]) {
        return Promise.resolve(translations.cache[language]);
    }

    return fetch(`/language/${language}`, { credentials: 'same-origin' })
        .then(response => {
            if (!response.ok) {
                return Promise.reject(`Invalid status code ${response.status} when fetching language ${language}`);
            }

            return response.json();
        })
        .then(translations => {
            window.translations.cache[language] = translations;

            return translations;
        });
};

const runChangeLanguage = ({ action, language }, dispatch) => {
    get(language)
        .then(_ => {
            window.translations.selected = language;

            dispatch(action, language);
        })
        .catch(console.error);
};

export const changeLanguage = ({ action, language }) => ([
    runChangeLanguage,
    {
        action: action,
        language: language
    }
]);

export const t = (key, ...args) => {
    const language = selectedLanguage();
    const translation = language[key];

    if (!translation) {
        translations.stats.miss(key);
    } else {
        translations.stats.use(key);
    }

    return translation || '{{' + key + '}}';
};