﻿import 'whatwg-fetch';

import { ok, err } from './result';

const cookies = () => {
    return decodeURIComponent(document.cookie)
        .split(';')
        .map(str => str.trim().split('='))
        .reduce((acc, current) => {
            acc[current[0]] = current[1];

            return acc;
        }, {});
};

const getCsrfToken = () => {
    return cookies()['CSRF-Token'] || '';
};

const isClientError = statusCode => statusCode >= 400 && statusCode < 500;
const isServerError = statusCode => statusCode >= 500 && statusCode < 600;

const deserializationError = error => Promise.reject({
    type: 'serialization',
    details: error
});

const clientError = details => Promise.reject({
    type: 'client-error',
    details: details
});

const serverError = response => Promise.reject({
    type: 'server-error',
    details: response
});

const handleResponse = response => {
    if (isServerError(response.status)) {
        return serverError(response);
    }

    return response.json()
        .catch(deserializationError)
        .then(body => {
            if (isClientError(response.status)) {
                return clientError(body);
            }

            return body;
        });
};

const send = (url, request) => fetch(url, request)
    .then(handleResponse)
    .then(ok)
    .catch(err);

const runGet = ({ action, url }, dispatch) => 
    send(url).then(result => dispatch(action, result));

const runPost = ({ action, url, data }, dispatch) => {
    const request = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRF-Token': getCsrfToken()
        },
        credentials: 'same-origin',
        body: JSON.stringify(data)
    };

    return send(url, request)
        .then(result => dispatch(action, result));
};

export const get = ({ action, url }) => ([
    runGet,
    {
        action: action,
        url: url
    }
]);

export const post = ({ action, url, data }) => ([
    runPost,
    {
        action: action,
        url: url,
        data: data
    }
]);