﻿import { h } from 'hyperapp';

import * as Http from '../http';
import * as Navigation from '../navigation';
import * as Routes from '../routes';

import { t } from '../translations';
import { hasErrors, required } from '../validation';
import { Dialog, ActionBar, DialogAction } from '../dialog';
import { Form, TextInput, SubmitButton, ValidationErrors, formValue, invalidate, valueOf } from '../forms';
import { Page } from '../page';

import * as LoginPage from './login';
import { appendQueryParameters } from '../uri';


const CloseDialog = state => [
    state,
    Navigation.back()
];

const SecurityCodeChanged = (state, newValue) => ({
    ...state,
    code: newValue
});

const SecurityCodeValidated = (state, result) => result
    .map(response => {
        switch (response.state) {
            case 'success':
                return [state, Navigation.loadUrl(response.returnUrl)];

            default:
                return [state, Routes.navigateToError({}, 'unexpected-response')];
        }
    })
    .resolve(error => {
        switch (error.type) {
            case 'client-error':
                return {
                    ...state,
                    loading: false,
                    code: invalidate(state.code, error.details.code)
                };

            default:
                return [state, Routes.navigateToError({}, error.type)];
        }
    });

const SecurityCodeEntered = (state, ev) => (
    [{ ...state, loading: true }
        , Http.post({
            action: SecurityCodeValidated,
            url: '/login/two-factor',
            data: {
                securityCode: valueOf(state.code),
                returnUrl: state.returnUrl
            }
        })]
);

const SecurityCodeSent = (state, result) => result.map(response => {
        switch (response.state) {
            case 'success':
                return {
                    ...state,
                    loading: false
                };

            default:
                return [state, Routes.navigateToError({}, 'unexpected-response')];
        }
    })
    .resolve(error => {
        switch (error.type) {
            case 'client':
                return {
                    ...state,
                    loading: false,
                    code: invalidate(state.code, error.details.code)
                };

            default:
                return [state, Routes.navigateToError({}, error.type)];
        }
    });

const ResendSecurityCode = (state, ev) => (
    [{ ...state, loading: true }, Http.post({
        action: SecurityCodeSent,
        url: appendQueryParameters('/login/two-factor/resend-security-code', { returnUrl: state.returnUrl }),
    })]
);

const TwoFactorAuthenticationDialog = ({ state }) => (
    <Dialog show={true}>
        <div>
            {t('two-factor-login.code-sent')}
        </div>
        <Form onSubmit={SecurityCodeEntered}>
            <TextInput id={'security-code'} value={state.code} placeholder={t('two-factor-login.security-code')} onChange={SecurityCodeChanged} />
            <SubmitButton label={t("two-factor-login.verify-button")} disabled={hasErrors(state.code)} />
            <ValidationErrors prefix={'two-factor-login'} values={[state.code]} />
        </Form>
        <ActionBar>
            <DialogAction href={Routes.login(state.returnUrl)} label="two-factor-login.return-to-login" onClick={CloseDialog} />
            <DialogAction href={Routes.twoFactorLogin(state.returnUrl)} label="two-factor-login.resend-security-code" onClick={ResendSecurityCode} />
        </ActionBar>
    </Dialog>
);

export const title = () => t('two-factor-login.title') + ' - Intelliplan AB';

export const init = (state, params) => {
    const loginPage = LoginPage.init(state, params);

    return [
        {
            loginState: loginPage[0],
            code: formValue('', required('security-code-required')),
            loading: false,
            returnUrl: params.returnUrl || settings.returnUrl || null,
        },
        loginPage[1],
        state.username === undefined && Routes.navigateToLogin(state, params.returnUrl)
    ]
};

export const view = state => (
    <Page name="two-factor">
        {LoginPage.view(state.loginState)}
        <TwoFactorAuthenticationDialog state={state} />
    </Page>
);