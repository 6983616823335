﻿import { h } from 'hyperapp';

import { t } from './translations';
import { preventDefault } from './events';

const intersperse = (arr, delimiter) => {
    if (arr.length <= 1) {
        return arr;
    }

    let result = [ arr[0] ];

    for (let i = 1; i < arr.length; ++i) {
        result.push(delimiter);
        result.push(arr[i]);
    }

    return result;
};

export const Dialog = ({ show, ...rest }, children) => {
    if (!show) {
        return null;
    }

    return (
        <div class="dialog" {...rest}>
            <div class="body">
                {children}
            </div>
        </div>
    );
};

export const Title = ({ title, ...attrs }) => (
    <div className="title">
        <h1>{title}</h1>
    </div>
);

export const ActionBar = ({ ...attrs }, children) => (
    <div {...attrs} class="button-bar">
        {intersperse(children, (<span> | </span>))}
    </div>
);

export const DialogAction = ({ href, label, onClick }) => (
    <a href={href} onClick={preventDefault(onClick)}>{t(label || 'global.close-button')}</a>
);