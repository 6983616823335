﻿import { h } from 'hyperapp';

import { isTruthy } from './utils';

const Spinner = ({ size }) => {
    const sizeInPixels = (size || 40) + 'px';

    const style = {
        width: sizeInPixels,
        height: sizeInPixels
    }; 

    return (
        <div class="spinner" style={style}>
            <div role="progressbar" style={style}>
                <svg viewBox="22 22 44 44">
                    <circle cx="44" cy="44" r="20.2" fill="none" stroke-width="3.6"></circle>
                </svg>
            </div>
        </div>
    );
};

export const Page = ({ name, className, loading }, children) => {
    const classes = ['page', name, className, loading ? 'loading' : null]
        .filter(isTruthy)
        .join(' ');
    
    return (
        <div className={classes}>
            {loading ? (<Spinner />) : children}
        </div>
    )
};